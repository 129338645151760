import React from 'react'
import { HashLink } from 'react-router-hash-link'
import AboutUsImg from '../../assets/images/aboutUs5.png'

const AboutUs = () => {
    return (
        <>
            <section id="smartcars" className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Kim jesteśmy
                        </h1>
                        <p className="mb-8 leading-relaxed"><span className="logo">SMART <span className='text-maincolor'>CARS</span></span> jesteśmy zespołem specjalizującym się w sprzedaży samochodów osobowych i dostawczych z polskich sieci dealerskich oraz obsługą zakupu samochodu na zamówienie. Wszystkie samochody pochodzą z polskich salonów i posiadają szczegółowo udokumentowane przebiegi. Wychodząc na przeciw naszym klientom udostępniamy pełną historię serwisową każdego pojazdu. Posiadamy bogate doświadczenie, szeroką wiedzę oraz wachlarz specjalistycznych umiejętności.</p>
                        <div className="flex justify-center">
                            <HashLink smooth to={'#oferta'} className="flex mx-auto text-maincolor font-bold uppercase bg-transparent border-2 border-maincolor py-4 px-4 focus:outline-none hover:bg-maincolor hover:text-white transition-all">Nasza oferta</HashLink>
                        </div>
                    </div>
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img className="object-cover object-center rounded" alt="hero" src={AboutUsImg} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs