import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import smartCarsLogo from '../../assets/images/smart-cars-logo.svg'
import menuOpenIcon from '../../assets/images/menu-open.svg'
import menuCloseIcon from '../../assets/images/menu-close.svg'

const Header = () => {

    const [isMenuOpen, setMenuOpen] = useState(false)

    const links = [
        {
            id: "1",
            label: "O nas",
            hrefTo: "#smartcars"
        },
        {
            id: "2",
            label: "Finansowanie",
            hrefTo: "#finansowanie"
        },
        {
            id: "3",
            label: "Oferta",
            hrefTo: "#oferta"
        },
        {
            id: "4",
            label: "Zakup",
            hrefTo: "#zakup"
        },
        {
            id: "5",
            label: "Kontakt",
            hrefTo: "#kontakt"
        }
    ]

    const setMenu = () => {
        setMenuOpen(!isMenuOpen);
    }

    return (
        <>
            <header className="text-zinc-400 bg-zinc-900 body-font sticky top-0 z-50 border-b border-zinc-800">
                <div className="container mx-auto flex flex-wrap p-5 flex-row items-center justify-between">
                    <HashLink smooth to={"#"} className="flex title-font font-medium items-center text-white mb-0">
                        <img src={smartCarsLogo} alt="Smart-Cars Logo" className=' max-w-12 lg:max-w-32'/>
                        <span className="ml-3 text-2xl lg:text-4xl logo">SMART <span className='text-maincolor'>CARS</span></span>
                    </HashLink>
                    <nav className={isMenuOpen? "flex md:ml-auto bg-zinc-900 left-0 right-0 top-[73px] absolute lg:static lg:flex flex-col lg:flex-row flex-wrap lg:items-center text-base justify-center" : "hidden flex md:ml-auto bg-zinc-900 left-0 right-0 top-[73px] absolute lg:static lg:flex flex-col lg:flex-row flex-wrap lg:items-center text-base justify-center"}>
                        {
                            links.map((val) => {
                                return <HashLink onClick={setMenu} smooth to={val.hrefTo} className="mr-5 text-white p-8 lg:p-0 hover:text-maincolor uppercase" key={val.id}>{val.label}</HashLink>
                            })
                        }
                    </nav>
                    <img onClick={()=>{setMenu()}} className="w-8 lg:hidden" src={!isMenuOpen ? menuOpenIcon : menuCloseIcon} alt="" />
                </div>
            </header>
        </>
    )
}

export default Header