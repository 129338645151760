import React from 'react'

import Header from '../components/header/Header'
import Hero from '../components/hero/Hero'
import AboutUs from '../components/aboutUs/AboutUs'
import Funding from '../components/funding/Funding'
import Offert from '../components/offert/Offert'
import Steps from '../components/steps/Steps'
import Contact from '../components/contact/Contact'
import Footer from '../components/footer/Footer'

const Home = () => {
    return (
        <>
            <Header />
            <Hero />
            <AboutUs />
            <Funding />
            <Offert />
            <Steps />
            <Contact />
            <Footer />
        </>
    )
}

export default Home