import React from 'react'
import { useRoutes } from 'react-router-dom'

import Home from './pages/Home';
import Error from './pages/Error';

const App = () => {

  document.title = "Smart-Cars.pl - Krajowe Samochody z polskich salonów";

  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '*', element: <Error /> },
  ]);

  return routes;
}

export default App