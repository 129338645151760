import React from 'react'
import { HashLink } from 'react-router-hash-link';
import bgImage from '../../assets/images/bg6.jpg';

const Hero = () => {
    
    return (
        <>
            <section className="text-zinc-400 bg-zinc-900 body-font"
            style={{
                backgroundImage: `linear-gradient(rgba(24, 24, 27, 1) 0%, rgba(0, 0, 0, 0.8) 100%), url(${bgImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}
            >
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col min-h-[calc(100vh_-_72px)] md:min-h-[calc(100vh_-_77px)] lg:min-h-[calc(100vh_-_102px)]">
                        <div className="text-center lg:w-2/3 w-full">
                            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Samochody z polskich salonów</h1>
                            <p className="leading-relaxed mb-8"><span className='logo'>SMART <span className='text-maincolor'>CARS</span></span> to ekspert w sprzedaży samochodów osobowych i dostawczych z polskich salonów dealerskich. Oferujemy pojazdy z kompletną dokumentacją, obejmującą szczegółowe przebiegi i historie serwisowe. Dzięki bogatemu doświadczeniu, szerokiej wiedzy i specjalistycznym umiejętnościom gwarantujemy klientom transparentność oraz wysoką jakość obsługi.</p>
                            <div className="flex justify-center">
                                <HashLink smooth to={'#oferta'} className="flex mx-auto text-maincolor font-bold uppercase bg-transparent border-2 border-maincolor py-4 px-4 focus:outline-none hover:bg-maincolor hover:text-white transition-all">Oferta samochodowa</HashLink>
                            </div>
                        </div>
                </div>
            </section>
        </>
    )
}

export default Hero