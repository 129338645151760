import React from 'react'

import offert1Image from '../../assets/images/offert-1.jpg'
import offert2Image from '../../assets/images/offert-2.jpg'

const Offert = () => {
    return (
        <>
            <section id="oferta" className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="text-center mb-20">
                        <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Oferta</h1>
                        <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                            Zapoznaj się z naszą ofertą wysokiej jakości samochodów osobowych i dostawczych pochodzących z polskich salonów dealerskich. Oferujemy także usługę zakupu samochodu na zamówienie, dostosowanego do indywidualnych potrzeb klienta, z pełną transparentnością dzięki szczegółowo udokumentowanym przebiegom i historii serwisowej każdego pojazdu.</p>
                    </div>
                    <section className="text-gray-600 body-font">
                        <div className="container md:px-5 mx-auto">
                            <div className="flex flex-wrap justify-center mx-4 -mb-10 text-center">
                                <div className="lg:w-1/2 mb-10 md:px-12 lg:border-r">
                                    <div className="rounded-lg h-64 overflow-hidden">
                                        <img alt="content" className="object-cover object-center h-full w-full" src={offert1Image} />
                                    </div>
                                    <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3 uppercase">Auta na sprzedaż „od ręki”</h2>
                                    <p className="leading-relaxed text-base max-w-lg mx-auto">Znajdź wysokiej jakości samochody dostępne w ofercie, dostosowane do indywidualnych potrzeb klienta, z pełną transparentnością dzięki udokumentowanym przebiegom i historii serwisowej.</p>
                                    <button className="flex mx-auto mt-6 text-maincolor font-bold uppercase bg-transparent border-2 border-maincolor py-4 px-4 focus:outline-none hover:bg-maincolor hover:text-white transition-all">Zobacz naszą ofertę</button>
                                </div>
                                <div className="lg:w-1/2 mb-10 md:px-12">
                                    <div className="rounded-lg h-64 overflow-hidden">
                                        <img alt="content" className="object-cover object-center h-full w-full" src={offert2Image} />
                                    </div>
                                    <h2 className="title-font text-2xl font-medium text-gray-900 mt-6 mb-3 uppercase">Auta pod zamówienie</h2>
                                    <p className="leading-relaxed text-base max-w-lg mx-auto">
                                        Oferujmy usługę, która pozwala na wybranie samochodu i przystąpienie do licytacji za
                                        naszym pośrednictwem. Jako nasz klient, wyłącznie Ty ustalasz do jakiego momentu
                                        licytujemy bądź zakupujemy pojazd za pomocą opcji „kup teraz”.
                                        Regularnie odwiedzamy place aukcyjne, a także za pośrednictwem stron internetowych
                                        wyszukujemy wyjątkowe egzemplarze.</p>
                                    <button className="flex mx-auto mt-6 text-maincolor font-bold uppercase bg-transparent border-2 border-maincolor py-4 px-4 focus:outline-none hover:bg-maincolor hover:text-white transition-all">Wylicytuj samochód</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}

export default Offert